/**
 *
 * @param {T | null | undefined} value
 * @return {value is T}
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
    return value !== null && typeof value !== 'undefined';
}

/**
 * Determines if a value is a non-empty value.
 * @param {T | null | undefined} value A value
 * @return {value is T}
 */
export function empty<T>(value: T | null | undefined): value is T {
    return value === null || typeof value === 'undefined';
}

/**
 * Creates a list of chunks from an Array
 * @param {Iterable<V>} iterable The iterable to split
 * @param {number} chunksSize The max size of the chunk
 * @return {V[][]} An array of chunks.
 */
export function splitToChunks<V>(iterable: Iterable<V>, chunksSize: number): V[][] {
    if (!iterable) return [];
    const array = Array.from(iterable);
    const results = [];
    while (array.length > 0) {
        results.push(array.splice(0, chunksSize));
    }
    return results;
}

export function shuffle<V>(iterable: Iterable<V>) {
    if (!iterable) return [];
    const array = Array.from(iterable);
    let i, t, j;
    for (i = array.length - 1; i > 0; i -= 1) {
        t = array[i];
        j = Math.floor(Math.random() * (i + 1));
        array[i] = array[j];
        array[j] = t;
    }
    return array;
}
