import { GraphQLClient, Variables } from 'graphql-request';
import {
    AccordionsDocument,
    CarouselsDocument,
    ConditionTagListsDocument,
    getSdk,
    GoldenCutsDocument,
    GridsDocument,
    PageBlocks,
    pageFragmentFragment,
    PageQuery,
    PageQueryVariables,
    ParagraphsDocument,
    Sdk,
    TextAndImagesDocument,
    VideosDocument,
    FinancingExampleDocument,
    Partners,
    Locale,
    Stage,
} from './graphql';
import { DocumentNode } from 'graphql';
import * as Dom from 'graphql-request/dist/types.dom';
import { createContext, ReactNode, useContext } from 'react';

export type CmsClient = Sdk & Pick<GraphQLClient, 'rawRequest' | 'request'>;

const DEFAULT_API_HOST = 'api-eu-central-1.graphcms.com';
const DEFAULT_ENVIRONMENT = 'master';

export type GraphCmsConfig = {
    apiToken: string;
    host?: string;
    projectId: string;
    environment?: string;
    endpoint?: string;
};

export function graphCms(config: GraphCmsConfig): () => CmsClient {
    if (!config) throw new Error('Missing CMS config.');
    const { apiToken } = config || {};
    if (!apiToken) throw new Error('Missing CMS api token.');
    const AUTH_HEADERS = {
        Authorization: `Bearer ${apiToken}`,
    };

    let cmsClient: CmsClient;
    return () => {
        if (cmsClient) return cmsClient;

        let { endpoint } = config;
        if (!endpoint) {
            const { host = DEFAULT_API_HOST, projectId, environment = DEFAULT_ENVIRONMENT } = config;
            if (!projectId) throw new Error('Missing CMS project configuration');
            endpoint = `https://${host}/v2/${projectId}/${environment}`;
        }

        const client = new GraphQLClient(endpoint, {
            headers: AUTH_HEADERS,
        });

        const sdk = getSdk(client);
        cmsClient = {
            rawRequest: client.rawRequest.bind(client),
            request: client.request.bind(client),
            ...sdk,
        };
        return cmsClient;
    };
}

export type PageBlockTypes = PageBlocks['__typename'];

type Block = { id: string; __typename: string } & PageBlocks;
type Pages = { pages: Array<pageFragmentFragment> };
type PageResolver<V, R extends Pages> = (variables: V, requestHeaders?: Dom.RequestInit['headers']) => Promise<R>;
type GetPageProps<V, R extends Pages> = {
    requestHeaders?: Dom.RequestInit['headers'];
    resolver?: PageResolver<V, R>;
    collectors?: Partial<Record<NonNullable<PageBlockTypes>, (block: PageBlocks) => string[]>>;
};

export async function getPage<V = PageQueryVariables, R extends Pages = PageQuery>(
    variables: V,
    client: CmsClient,
    props?: GetPageProps<V, R>
): Promise<R & { collected?: Partial<Record<NonNullable<PageBlockTypes>, string[]>> }> {
    const { requestHeaders = {}, resolver = client.Page, collectors = {} } = props || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await resolver(variables, requestHeaders);
    const {
        pages: [page = null],
        ...otherResponse
    } = response;
    if (!page) return response as R;

    const { blocks = [], ...other } = page;
    const toQuery = blocks
        .filter(({ __typename: type }) => ID_ONLY_BLOCKS.includes(type))
        .reduce((q, block) => {
            if ('id' in block) {
                const ids = q[block.__typename] || [];
                ids.push(block.id);
                q[block.__typename] = ids;
            }
            return q;
        }, {} as Record<string, string[]>);

    const requests: [string, DocumentNode, Variables][] = Object.entries(toQuery).map(([type, ids]) => [
        type,
        ID_ONLY_BLOCK_MAPPINGS[type],
        { ...variables, ids },
    ]);

    const results = await Promise.all(
        requests.map(([type, doc, variables]) =>
            client.request(doc, variables, requestHeaders).then(r => [type, Object.values(r).flat() as Block[]])
        )
    );

    const data: Record<string, Block[]> = Object.fromEntries(results);
    const collected: Partial<Record<NonNullable<PageBlockTypes>, string[]>> = {};

    const enhancedBlocks = blocks
        .map(block => {
            if (!('id' in block)) return block;
            const { id, __typename: type } = block;
            if (!ID_ONLY_BLOCKS.includes(type)) return block;
            const d = data[type];
            if (!d) return block;
            const [enhancedBlock] = d.filter(({ id: blockId }) => blockId === id);
            if (!enhancedBlock) return block;
            return enhancedBlock;
        })
        .map(block => {
            const { __typename: type } = block;
            const c = collectors[type];
            if (c) {
                const vs = collected[type] || [];
                const v = c(block as PageBlocks);
                collected[type] = [...vs, ...v];
            }
            return block;
        });

    const result: R & { collected?: Partial<Record<NonNullable<PageBlockTypes>, string[]>> } = {
        ...otherResponse,
        pages: [{ blocks: enhancedBlocks, ...other }],
    } as R;
    if (Object.keys(collected).length > 0) result.collected = collected;
    return result;
}

export type PartnersLogoType = { fileName: string; url: string; partner: Partners }[];

export async function getPartnersData(client: CmsClient, options: { locale: Locale; stage: Stage }) {
    const { locale, stage } = options;
    return client.Partners({ partners: Object.values(Partners), locale, stage });
}

const ID_ONLY_BLOCK_MAPPINGS: Record<string, DocumentNode> = {
    Accordion: AccordionsDocument,
    Carousel: CarouselsDocument,
    ConditionTagList: ConditionTagListsDocument,
    Grid: GridsDocument,
    Paragraph: ParagraphsDocument,
    Video: VideosDocument,
    FinancingExample: FinancingExampleDocument,
    GoldenCut: GoldenCutsDocument,
    TextAndImage: TextAndImagesDocument,
};
const ID_ONLY_BLOCKS = Object.keys(ID_ONLY_BLOCK_MAPPINGS);

export type BffProviderProps = {
    cmsClient: () => CmsClient;
    children: ReactNode;
};

const GraphCmsContext = createContext<(() => CmsClient) | null>(null);

export function GraphCmsProvider({ cmsClient, children }: BffProviderProps) {
    if (!cmsClient) throw new Error('CMS client must be provided');

    return <GraphCmsContext.Provider value={cmsClient}>{children}</GraphCmsContext.Provider>;
}

export function useGraphCms(): CmsClient {
    const cmsClient = useContext(GraphCmsContext);
    if (!cmsClient) throw new Error('CMS client not yet initialized');
    return cmsClient();
}
