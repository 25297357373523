// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SupportedRegions } from '@/lib/api/bff';

// 544117 = bundesland Hamburg
// 544116 = bundesland Berlin
export const getDefaultLocation = (region: string): { id: number; name: string } => {
    if (!region) throw new Error('Region is not defined');
    const upperCaseRegion = region.toUpperCase();

    switch (upperCaseRegion) {
        case SupportedRegions.BERLIN:
            return { id: 544116, name: 'Berlin' };
        case SupportedRegions.HAMBURG:
            return { id: 544117, name: 'Hamburg' };
        case SupportedRegions.FRANKFURT_MAIN:
            return { id: 531502, name: 'Frankfurt am Main' };
        default:
            throw new Error('Region is not supported');
    }
};

export const getDefaultBoundaries = (region: string): { lat: number; lng: number }[] => {
    if (!region) throw new Error('Region is not defined');

    switch (region) {
        case SupportedRegions.BERLIN:
            return [
                { lat: 52.339629, lng: 13.089159 },
                { lat: 52.675454, lng: 13.761117 },
            ];
        case SupportedRegions.HAMBURG:
            return [
                { lat: 53.395, lng: 9.75 },
                { lat: 53.75, lng: 10.25 },
            ];
        case SupportedRegions.FRANKFURT_MAIN:
            return [
                { lat: 49.9, lng: 8.4 },
                { lat: 50.3, lng: 8.9 },
            ];
        default:
            throw new Error('Region is not supported');
    }
};
