import { GraphQLClient } from 'graphql-request';
import { getSdk, Sdk } from './graphql';
import { createContext, useContext, ReactNode } from 'react';
import { ApiConfig } from './bff.types';

export function websiteBffApi(config: ApiConfig): () => Sdk {
    const { apiToken } = config;
    if (!apiToken) throw new Error('Missing website-bff api token.');
    const AUTH_HEADERS = {
        'x-api-key': `${apiToken}`,
    };

    let sdk: Sdk;
    return () => {
        if (sdk) return sdk;

        const { stage } = config;
        const endpoint =
            stage === 'local'
                ? process.env['NEXT_PUBLIC_LOCAL_BFF'] || 'https://graphql.dev.ownr.eu/graphql'
                : `https://graphql.${stage === 'prod' ? '' : `${stage}.`}ownr.eu/graphql`;

        const client = new GraphQLClient(endpoint, {
            headers: AUTH_HEADERS,
        });

        sdk = getSdk(client);
        return sdk;
    };
}

export type BffProviderProps = {
    sdk: () => Sdk;
    children: ReactNode;
};

const BffContext = createContext<(() => Sdk) | null>(null);

export function BffProvider({ sdk, children }: BffProviderProps) {
    if (!sdk) throw new Error('SDK must be provided');

    return <BffContext.Provider value={sdk}>{children}</BffContext.Provider>;
}
export function useBff(): Sdk {
    const sdk = useContext(BffContext);
    if (!sdk) throw new Error('SDK not yet initialized');
    return sdk();
}
