export const formatCurrency = (locale: string, amount: number) => {
    const currencyFormatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    return currencyFormatter.format(amount);
};
