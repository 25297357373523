import { awsRegion, CognitoProfileData, refreshCognitoToken } from '@/lib/env';
import { CustomAuthUser, Session, TokenSet, User } from 'next-auth';
import moment from 'moment/moment';
import jwtDecode from 'jwt-decode';
import { JWT } from 'next-auth/jwt';

/**
 * Maps the fllat JWT datastructure  to a Session/User object
 * @param session
 * @param token
 */
export async function mapSession({ session, token }: { session: Session; token: JWT }): Promise<Session> {
    const { idToken, refreshToken, accessTokenExpiry, ...other } = token ?? {};

    if (token && session?.user) {
        session.user = {
            ...session.user,
            ...other,
        };
    }

    if (token?.accessTokenExpiry) {
        session.accessTokenExpiry = accessTokenExpiry as number;
    }
    if (token?.idToken) {
        session.idToken = idToken as string;
    }
    if (token?.refreshToken) {
        session.refreshToken = refreshToken as string;
    }
    return session;
}

type JwtParams<A, P> = {
    token: JWT;
    user: CustomAuthUser | null;
    account: A;
    profile: P;
    trigger?: 'signIn' | 'signUp' | 'update';
    session: Session & { impersonating: User; action: string };
};

/**
 * Muchness User and Session information into a flat structure, 'data' attribute on User only present after
 * Cognito CredentialsProvider authorize
 * @param params The params
 * @returns JWT
 */
export async function createJwt<A, R>(params: JwtParams<A, R>): Promise<JWT> {
    const { token, user, trigger } = params;
    const { data, ...userBase } = user ?? {};
    const { refreshToken, accessTokenExpiry, idToken } = data || {};

    const expiry =
        typeof accessTokenExpiry !== 'undefined' && accessTokenExpiry !== null
            ? moment(accessTokenExpiry).subtract(5, 'minutes')
            : null;
    const now = moment();

    const nt = {
        ...token,
        ...userBase,
        accessTokenExpiry: accessTokenExpiry ?? token.accessTokenExpiry,
        idToken: idToken ?? token.idToken,
        refreshToken: refreshToken ?? token.refreshToken,
    };

    if (trigger === 'update') {
        return {
            ...nt,
            impersonating: params.session.action === 'logout' ? undefined : params.session.impersonating,
        };
    }

    if (!expiry || now.isBefore(expiry)) {
        return nt;
    }

    const { idToken: it, refreshToken: rt, expiresIn } = await refreshCognitoToken(token.sub, refreshToken as string);
    nt.idToken = it;

    const jwt = { ...nt, accessTokenExpiry: moment().add(expiresIn, 'seconds').valueOf(), refreshToken: rt };
    console.log('jwt', jwt);
    return jwt;
}

export const normalizeUserGroups = (groups: string[]) =>
    groups
        .map((g: string) => {
            if (g.startsWith(awsRegion)) {
                // ID provider groups are name eu-central-1_{$pool-id}}_${provider} so map it the provider name
                const [, , name] = g.split('_') ?? [];
                if (name) return name;
            }
            return g;
        })
        .filter(Boolean);

export function createUser<P extends CognitoProfileData>(profile: P, token: TokenSet): User {
    const {
        'custom:account_id': accountId,
        'custom:reference_id': referenceId,
        'custom:rating': rating = null,
        'custom:referrer': referrer,
        'custom:user_reference_id': userReferenceId,
        'cognito:username': name,
        sub: id,
        email,
        given_name: firstName,
        family_name: lastName,
    } = profile;
    const { access_token: accessToken } = token;
    const decodedToken = accessToken ? jwtDecode(accessToken) : {};
    const groups = normalizeUserGroups(decodedToken['cognito:groups'] ?? []);
    return {
        id,
        email,
        name,
        accountId: (accountId ?? referenceId ?? userReferenceId) as string,
        firstName,
        lastName,
        rating,
        groups,
        referrer,
        impersonating: undefined,
    };
}
