import React, { useEffect, useRef, useState, useMemo } from 'react';

export function useElementVisibility(): [React.RefObject<HTMLDivElement>, boolean] {
    const targetRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsVisible(entry.isIntersecting);
        });

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [targetRef.current]);

    return [targetRef, isVisible];
}

export function useBootstrapBreakpointValue(
    breakpoint: 'sm' | 'md' | 'lg',
    dependencies: React.DependencyList
): string {
    const cssBreakpointName = `--breakpoint-${breakpoint}`;
    const value = useMemo(() => getComputedStyle(document.body).getPropertyValue(cssBreakpointName), dependencies);
    return value;
}
