export function hexToRgb(color: string | undefined, opacity = 1) {
    if (!color?.length) return 'rgb(0,0,0)';
    const c = color.length === 6 ? color : color.length > 6 ? color.slice(0, 6) : color.padStart(6, '0');
    const r = parseInt(c.slice(0, 2), 16);
    const g = parseInt(c.slice(2, 4), 16);
    const b = parseInt(c.slice(4, 6), 16);

    return opacity < 1 ? `rgba(${r},${g},${b},${opacity})` : `rgb(${r},${g},${b})`;
}

const CLASS_TO_RBG: Record<string, (opacity?: number) => string> = {
    white: opacity => hexToRgb('ffffff', opacity),
    black: opacity => hexToRgb('000000', opacity),
    primary: opacity => hexToRgb('fa695a', opacity),
    gray: opacity => hexToRgb('fa695a', opacity),
    secondary: opacity => hexToRgb('b4dcdc', opacity),
    coral: opacity => hexToRgb('b4dcdc', opacity),
    ternary: opacity => hexToRgb('725bff', opacity),
    velvet: opacity => hexToRgb('725bff', opacity),
    dark: opacity => hexToRgb('32374b', opacity),
};

export function classToRgb(className: string, opacity?: number) {
    const f = CLASS_TO_RBG[className];
    return f ? f(opacity) : CLASS_TO_RBG['black'](opacity);
}

export function contrastTextClass(className?: string | null, defaultClass = 'text-dark') {
    if (!className) return 'text-white';
    const c = className.match(/^bg-/i) ? className.slice(3).toLowerCase() : className.toLowerCase();
    switch (c) {
        case 'velvet':
        case 'ternary':
        case 'coral':
        case 'secondary':
        case 'dark':
        case 'gray':
        case 'primary':
            return 'text-white';
        case 'white':
            return 'text-dark';
        default:
            break;
    }

    if (c.indexOf('grey') >= 0) return c.indexOf('light') >= 0 ? 'text-dark' : 'text-white';

    return defaultClass;
}

export function isDarkBackground(className?: string | null): boolean {
    if (!className) return true;
    const c = className.match(/^bg-/i) ? className.slice(3).toLowerCase() : className.toLowerCase();
    switch (c) {
        case 'dark':
        case 'primary':
            return true;
        case 'secondary':
        case 'white':
            return false;
        default:
            return c.indexOf('grey') >= 0 && c.indexOf('light') >= 0;
    }
}

export function backgroundStyleToClass(style: string | undefined | null, defaultValue?: string | null) {
    if (!style) {
        if (defaultValue?.startsWith('bg-')) return defaultValue;
        else return `bg-${defaultValue}`;
    }
    if (style.startsWith('bg-')) return style;
    return `bg-${style}`;
}

export function enumToBackground(bg: string | undefined | null) {
    if (!bg) return '';
    if (bg.startsWith('bg-')) return bg;
    if (bg.startsWith('bg-')) return bg;
    return 'bg-' + bg.replace('_', '-');
}

export function enumToTextColor(value?: string | null) {
    if (!value) return '';
    if (value.startsWith('text-')) return value;
    return 'text-' + value.replace('_', '-');
}

export function splitOldButtonEnum(value?: string | null): { color?: string; outline?: boolean } {
    if (!value) return { color: 'velvet', outline: false };
    const [outlineOrColor, color] = value.split('_');
    const outline = outlineOrColor === 'outline';
    return { color: outline ? color : outlineOrColor, outline };
}
