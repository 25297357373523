import {
    AdminListGroupsForUserCommand,
    AttributeType,
    CognitoIdentityProviderClient,
    ConfirmForgotPasswordCommand,
    ConfirmSignUpCommand,
    ForgotPasswordCommand,
    GetUserCommand,
    InitiateAuthCommand,
    ListUsersCommand,
    ResendConfirmationCodeCommand,
    RespondToAuthChallengeCommand,
    VerifyUserAttributeCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { awsRegion, cognitoClientId, cognitoClientSecret, cognitoIdentityPoolId, cognitoUserPool } from '@/lib/env';
import { Session, User } from 'next-auth';
import { notEmpty, snakeCase } from '../utilities';
import { createHmac } from 'crypto';
import jwtDecode from 'jwt-decode';
import { notify } from '../utilities/logger';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { normalizeUserGroups } from '../nextauth';

export type ResultWithAttempts = { success: boolean; attempts?: number; error?: string };

const client = new CognitoIdentityProviderClient({
    region: awsRegion || 'eu-central-1',
});

export async function confirmSignUp(
    username: string,
    code: string
): Promise<ResultWithAttempts & { message?: string }> {
    try {
        const command = new ConfirmSignUpCommand({
            ClientId: cognitoClientId,
            ConfirmationCode: code,
            Username: username,
            SecretHash: secretHash(username),
        });
        const { $metadata } = await client.send(command);
        const { attempts = 0 } = $metadata || {};
        return { success: true, attempts };
    } catch (error) {
        notify(error, 'Cognito/ConfirmSignUP', maskUsername(username));
        const code = createErrorCode(error);
        return { success: false, error: code, message: error?.toString() };
    }
}

export function issuer() {
    return `https://${userPool()}`;
}

export function userPool() {
    return `cognito-idp.${awsRegion}.amazonaws.com/${cognitoUserPool}`;
}

export async function initiateAuth(
    username: string,
    password: string
): Promise<{
    user: User;
    accessToken?: string;
    idToken?: string;
    refreshToken?: string;
    expiresIn?: number;
}> {
    try {
        const name = username.toLocaleLowerCase();
        const command = new InitiateAuthCommand({
            ClientId: cognitoClientId,
            AuthFlow: 'USER_PASSWORD_AUTH',
            AuthParameters: {
                USERNAME: name,
                PASSWORD: password,
                SECRET_HASH: secretHash(name),
            },
        });

        const { AuthenticationResult } = await client.send(command);
        const { AccessToken, IdToken, RefreshToken, ExpiresIn } = AuthenticationResult ?? {};

        if (!AccessToken) new Error('unexpected_error');

        const { UserAttributes = [], Username } = await client.send(new GetUserCommand({ AccessToken: AccessToken }));

        const user = createUserFromAttribute(UserAttributes, { name: Username });
        const decodedToken = jwtDecode(AccessToken ?? '');
        const groups = normalizeUserGroups(decodedToken['cognito:groups'] ?? []);

        return {
            user: {
                ...user,
                groups,
            },
            accessToken: AccessToken,
            idToken: IdToken,
            refreshToken: RefreshToken,
            expiresIn: ExpiresIn,
        };
    } catch (error) {
        if (error?.message && !Object.values(CognitoNoLogErrorMessagesEnum).includes(error?.message)) {
            notify(error, 'Cognito/initiateAuth', maskUsername(username));
        }
        const code = createErrorCode(error);
        console.log('initiateAuth failed:', code, '-', error);
        throw new Error(code);
    }
}

function createObjectFromAttribbutes(attributes: AttributeType[], defaults = {}): Record<string, string> | undefined {
    if (!attributes) return undefined;
    return attributes.reduce((acc, { Name, Value }) => ({ ...acc, [Name ?? '']: Value }), defaults);
}

function createUserFromAttribute(attributes: AttributeType[], addtional = {}): User & Record<string, string> {
    const data = createObjectFromAttribbutes(attributes, addtional);
    if (!data) return null;
    const {
        locale = 'de',
        email,
        given_name: firstName,
        family_name: lastName,
        'cognito:username': name,
        'custom:account_id': accountId,
        'custom:reference_id': referenceId,
        'custom:user_reference_id': userReferenceId,
        'custom:referer': referrer,
        'custom:rating': rating,
        sub,
    } = data ?? {};

    return {
        id: sub,
        accountId: accountId ?? referenceId ?? userReferenceId ?? null,
        firstName,
        lastName,
        name,
        email,
        rating,
        referrer,
        locale: locale.toLowerCase(),
        ...addtional,
    };
}

export async function refreshCognitoToken(
    sub: string,
    refreshToken: string
): Promise<{ accessToken?: string; idToken?: string; refreshToken?: string; expiresIn?: number }> {
    try {
        const command = new InitiateAuthCommand({
            ClientId: cognitoClientId,
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: { REFRESH_TOKEN: refreshToken, SECRET_HASH: secretHash(sub) },
        });
        const { AuthenticationResult } = await client.send(command);
        const { AccessToken, IdToken, RefreshToken, ExpiresIn } = AuthenticationResult ?? {};

        return { accessToken: AccessToken, idToken: IdToken, refreshToken: RefreshToken, expiresIn: ExpiresIn };
    } catch (error) {
        notify(error, 'Cognito/refreshCognitoToken');
        const code = createErrorCode(error);
        console.log('refresh failed:', code, '-', error);
        throw new Error(code);
    }
}

export async function changeTemporaryPassword(
    username: string,
    temporaryPassword: string,
    newPassword: string
): Promise<{ success: boolean; message?: string; code?: string }> {
    try {
        const command = new InitiateAuthCommand({
            ClientId: cognitoClientId,
            AuthFlow: 'USER_PASSWORD_AUTH',
            AuthParameters: {
                USERNAME: username,
                PASSWORD: temporaryPassword,
                SECRET_HASH: secretHash(username),
            },
        });
        const { ChallengeName, Session } = await client.send(command);
        if (ChallengeName !== 'NEW_PASSWORD_REQUIRED')
            return {
                success: false,
                message: 'Invalid challenge response.',
                code: 'invalid_challenge_response',
            };

        await client.send(
            new RespondToAuthChallengeCommand({
                ChallengeName: 'NEW_PASSWORD_REQUIRED',
                ClientId: cognitoClientId,
                Session,
                ChallengeResponses: {
                    USERNAME: username,
                    NEW_PASSWORD: newPassword,
                    SECRET_HASH: secretHash(username),
                },
            })
        );

        return { success: true };
    } catch (error) {
        notify(error, 'Cognito/changeTemporaryPassword', maskUsername(username));
        const code = createErrorCode(error);
        return { success: false, code };
    }
}

export async function verifyUserAttribute(
    accessToken: string,
    code: string,
    attributeName = 'email'
): Promise<ResultWithAttempts> {
    try {
        const command = new VerifyUserAttributeCommand({
            AccessToken: accessToken,
            Code: code,
            AttributeName: attributeName,
        });
        const { $metadata } = await client.send(command);
        const { attempts = 0 } = $metadata || {};
        return { success: true, attempts };
    } catch (error) {
        notify(error, 'Cognito/verifyUserAttribute');
        return { success: false, error: createErrorCode(error) };
    }
}

export async function resendConfirmationCode(username: string): Promise<ResultWithAttempts> {
    try {
        const command = new ResendConfirmationCodeCommand({
            ClientId: cognitoClientId,
            Username: username,
            SecretHash: secretHash(username),
        });
        const { $metadata } = await client.send(command);
        const { attempts = 0 } = $metadata || {};
        return { success: true, attempts };
    } catch (error) {
        notify(error, 'Cognito/resendConfirmationCode', maskUsername(username));
        return { success: false, error: createErrorCode(error) };
    }
}

export async function forgotPassword(username: string): Promise<ResultWithAttempts> {
    try {
        const command = new ForgotPasswordCommand({
            ClientId: cognitoClientId,
            Username: username,
            SecretHash: secretHash(username),
        });
        const { $metadata } = await client.send(command);
        const { attempts = 0 } = $metadata || {};
        return { success: true, attempts };
    } catch (error) {
        notify(error, 'Cognito/forgotPassword', maskUsername(username));
        return { success: false, error: createErrorCode(error) };
    }
}

export async function confirmForgotPassword(
    username: string,
    code: string,
    password: string
): Promise<ResultWithAttempts> {
    try {
        const command = new ConfirmForgotPasswordCommand({
            ClientId: cognitoClientId,
            Username: username,
            ConfirmationCode: code,
            Password: password,
            SecretHash: secretHash(username),
        });
        const { $metadata } = await client.send(command);
        const { attempts = 0 } = $metadata || {};
        return { success: true, attempts };
    } catch (error) {
        notify(error, 'Cognito/confirmForgotPassword', maskUsername(username));
        return { success: false, error: createErrorCode(error) };
    }
}

export async function getUserByEmail(email: string, session: Session): Promise<User> {
    const client = new CognitoIdentityProviderClient({
        region: awsRegion || 'eu-central-1',
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: awsRegion },
            identityPoolId: cognitoIdentityPoolId,
            userIdentifier: session.user.id,
            logins: {
                [userPool()]: session.idToken,
            },
        }),
    });

    const listUsersCommand = new ListUsersCommand({
        UserPoolId: cognitoUserPool,
        Filter: `email = "${email}"`,
    });

    const { Users = [] } = (await client.send(listUsersCommand)) ?? {};

    const accounts = Users.map(({ Attributes, UserCreateDate: createdDate, Username: userName }) =>
        createUserFromAttribute(Attributes, { createdDate, userName })
    )
        .filter(notEmpty)
        .filter(({ accountId }) => !!accountId);

    // because of the SSO there might be more than one account with the email in Cognito
    const accountIds = accounts.reduce((acc, { accountId }) => {
        const count = acc[accountId] ?? 0;
        acc[accountId] = count + 1;
        return acc;
    }, {} as Record<string, number>);

    // take the account with the most occurences, should be only one
    const [account = []] = Object.entries(accountIds).sort((a, b) => b[1] - a[1]);

    // select the most recent account
    const [user] =
        accounts
            .filter(({ accountId: id }) => !account[0] || id === account[0])
            .sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)) ?? [];

    if (!user) return null;

    // list the groups from cognito
    const listGroupsCommand = new AdminListGroupsForUserCommand({
        UserPoolId: cognitoUserPool,
        Username: user.userName,
    });

    const { Groups = [] } = (await client.send(listGroupsCommand)) ?? {};
    const groupNames =
        Groups.map(({ GroupName }) => GroupName)
            .filter(notEmpty)
            .filter(g => !g.startsWith(cognitoUserPool)) ?? [];

    return { ...user, groups: Array.from(new Set([...(user.groups ?? []), ...groupNames])) };
}

function secretHash(username: string): string {
    const hasher = createHmac('sha256', cognitoClientSecret);
    hasher.update(`${username}${cognitoClientId}`);
    return hasher.digest('base64');
}

function createErrorCode(e?: Error | unknown | null): string {
    const name: string =
        !e || typeof e !== 'object'
            ? 'unexpected'
            : 'name' in e
            ? (e.name as string)
            : 'message' in e
            ? (e.message as string)
            : 'unexpected';
    return ERROR_CODES[name] ?? 'unexpected_error';
}

enum CognitoNoLogErrorMessagesEnum {
    IncorrectPassword = 'Incorrect username or password.',
    UserIsNotConfirmed = 'User is not confirmed.',
    InvalidCode = 'Invalid verification code provided, please try again.',
    PasswordReset = 'Password reset required for the user',
}

enum CognitoErrorCodesEnum {
    CodeMismatchException = 'CodeMismatchException',
    ExpiredCodeException = 'ExpiredCodeException',
    TooManyFailedAttemptsException = 'TooManyFailedAttemptsException',
    NotAuthorizedException = 'NotAuthorizedException',
    UserNotFoundException = 'UserNotFoundException',
    CodeDeliveryFailureException = 'CodeDeliveryFailureException',
    UserNotConfirmedException = 'UserNotConfirmedException',
    PasswordResetRequiredException = 'PasswordResetRequiredException',
}

const ERROR_CODES: Record<string, string> = Object.fromEntries([
    ...[
        CognitoErrorCodesEnum.CodeMismatchException,
        CognitoErrorCodesEnum.ExpiredCodeException,
        CognitoErrorCodesEnum.TooManyFailedAttemptsException,
        CognitoErrorCodesEnum.NotAuthorizedException,
        CognitoErrorCodesEnum.CodeDeliveryFailureException,
        CognitoErrorCodesEnum.UserNotConfirmedException,
        CognitoErrorCodesEnum.PasswordResetRequiredException,
    ].map(e => {
        return [e, snakeCase(e.endsWith('Exception') ? e.substr(0, e.length - 9) : e)];
    }),
    [CognitoErrorCodesEnum.UserNotFoundException, 'not_authorized'],
    ['unexpected', 'unexpected_error'],
]);

function maskUsername(username: string) {
    const checkIfEmail = username.split('@');
    const firstPart = checkIfEmail[0];
    const firstPartLength = firstPart.length;
    const maskedFirstPortion = firstPart.slice(0, firstPartLength / 2) + '*'.repeat(firstPartLength / 2);
    return checkIfEmail.length > 1 ? `${maskedFirstPortion}@${checkIfEmail[1]}` : maskedFirstPortion;
}

export type CognitoProfileData = {
    sub: string;
    email: string;
    given_name: string;
    family_name: string;
    locale: string;
    'cognito:username'?: string;
    'custom:account_id'?: string;
    'custom:reference_id'?: string;
    'custom:rating'?: string | null;
    'custom:referrer'?: string | null;
    'custom:user_reference_id'?: string | null;
};
