const description = `Bei OWNR denken wir das Wohnen neu: Wir kaufen Wohnungen und Häuser, renovieren diese und verleasen sie. Die Vorteile? Wer vom Mieter zum OWNR wird, zieht schnell und bequem in sein persönliches Traumzuhause ein. Werde auch Du ein Teil von OWNR!`;
const title = `OWNR - Su suchst, Wir kaufen, Du wohnst.`;
const url = `https://www.ownr.eu`;

const seo = {
    title,
    titleTemplate: '%s | OWNR',
    description,
    openGraph: {
        description,
        title,
        type: 'website',
        url,
    },
};

export { seo as defaultSEO };
