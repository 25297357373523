import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { appVersion, bugsnagApiKey, stage } from './constants';
import { NotifiableError } from '@bugsnag/core/types/common';

if (bugsnagApiKey) {
    Bugsnag.start({
        apiKey: bugsnagApiKey,
        appVersion: appVersion,
        releaseStage: stage,
        collectUserIp: false,
        generateAnonymousId: false,
        enabledReleaseStages: ['prod', 'production', 'staging', 'dev'],
        plugins: [new BugsnagPluginReact(React)],
    });
}

export const notify = (
    error: NotifiableError,
    context?: string,
    userId?: string,
    requestData?: Record<string, any>
) => {
    if (!bugsnagApiKey) return;
    Bugsnag.notify(error, event => {
        if (context) event.context = context;
        if (userId) event.setUser(userId);
        if (requestData) event.addMetadata('request data', requestData);
    });
};
export const LoggerErrorBoundary = bugsnagApiKey && Bugsnag.getPlugin('react')?.createErrorBoundary(React);
