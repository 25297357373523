import { Session } from 'next-auth';

export type EventParameter = { [name: string]: unknown };
type WindowType = Window & typeof globalThis & { dataLayer?: unknown[] };

export function push(event: EventParameter): void {
    const w: WindowType | undefined = window;
    w?.dataLayer?.push(event);
}

export function event(name: string, values?: EventParameter): void {
    push({ event: name, ...values });
}

export function pageView(path: string, session: Session | undefined): void {
    const label = getPageViewLabel(path);
    event(EVENTS.PAGE_VIEW, { page: path, referrer: session?.user?.referrer, label, loggedIn: !!session });
}

const CATEGORIES = {
    APPLICATION: 'Application',
    BROKER_APPLICATION: 'Broker Application',
    VOBAHOME_APPLICATION: 'Vobahome Application',
    SEARCH: 'Search',
    LEASE_CALCULATOR: 'Lease Calculator',
    PROPERTY: 'Property',
    APPOINTMENT_SLOT: 'Appointment Slot',
    LEASE_REQUEST: 'Lease Request',
};

const EVENTS = {
    PAGE_VIEW: 'pageview',
    APPLICATION_STARTED: 'application_started',
    APPLICATION_SUCCESSFUL: 'application_successful',
    NAVIGATE_FORWARD: 'navigate_forward',
    NAVIGATE_BACKWARD: 'navigate_backward',
    LOGIN: 'login_clicked',
    SIGN_IN: 'sign_in',
    SIGN_OUT: 'sign_out',
    LOGOUT: 'logout_clicked',
    FIELD_TOUCHED: 'field_touched',
    CONTACT_SUCCESSFUL: 'contact_successful',
    MY_OWNR_CLICKED: 'myownr_clicked',
    APPLY_CLICKED: 'apply_clicked',
    PRIVATE_SELLER_SUCCESSFUL: 'private_seller_successful',
    LEASE_CALCULATOR_CITY_CHANGED: 'lease_calculator_city_changed',
    LEASE_CALCULATOR_VISIBLE: 'lease_calculator_visible',
    SEARCH_FIELD_TOUCHED: 'search_field_touched',
    SEARCH_ORDER_CHANGED: 'search_order_changed',
    SEARCH_LOAD_MORE_CLICKED: 'search_load_more_clicked',
    SEARCH_INITIATED: 'search_initiated',
    SEARCH_SAVE_OPTIONS_CLICKED: 'search_save_options_clicked',
    SEARCH_SAVE: 'search_save',
    SEARCH_PROPERTY_CLICKED: 'search_property_clicked',
    PROPERTY_BACK_BUTTON_CLICKED: 'property_back_button_clicked',
    PROPERTY_VIEWING_REQUEST_CLICKED: 'property_viewing_request_clicked',
    PROPERTY_VIEWING_REQUESTED: 'property_viewing_requested',
    ATTEND_VIEWING_REQUESTED: 'attend_viewing_requested',
    PROPERTY_LEASE_RATE_CLICKED: 'property_lease_rate_changed',
    PROPERTY_REFURBISHMENT_LEVEL_CLICKED: 'property_refurbishment_level_clicked',
    PROPERTY_REFURBISHMENT_INFO_OPENED: 'property_refurbishment_info_opened',
    PROPERTY_PROVIDER_LINK_CLICKED: 'property_provider_link_clicked',
    LEASE_REQUEST_INTERESTED_YES_CLICKED: 'lease_request_interested_yes_clicked',
    LEASE_REQUEST_INTERESTED_NO_CLICKED: 'lease_request_interested_no_clicked',
    LEASE_REQUEST_UPLOAD_PROPERTY_DATA_CLICKED: 'lease_request_upload_property_data_clicked',
    LEASE_REQUEST_ADD_TO_CALENDAR_CLICKED: 'lease_request_add_to_calendar_clicked',
    LEASE_REQUEST_APPOINTMENT_CONFIRM_CLICKED: 'lease_request_appointment_confirm_clicked',
    LEASE_REQUEST_APPOINTMENT_RESCHEDULE_CLICKED: 'lease_request_appointment_reschedule_clicked',
    LEASE_REQUEST_ATTEND_TO_EXISTING_APPOINTMENT_CLICKED: 'lease_request_attend_to_existing_appointment_clicked',
    LEASE_REQUEST_BOOK_ANOTHER_APPOINTMENT_CLICKED: 'lease_request_book_another_appointment_clicked',
    LEASE_REQUEST_LIKED_YES_CLICKED: 'lease_request_liked_yes_clicked',
    LEASE_REQUEST_LIKED_NO_CLICKED: 'lease_request_liked_no_clicked',
    LEASE_REQUEST_LIKED_NO_VISIT_CLICKED: 'lease_request_liked_no_visit_clicked',
    LEASE_REQUEST_CANCELLED: 'lease_request_cancelled',
    READ_MORE_CLICKED: 'read_more_clicked',
    EDIT_PROPERTY_CLICKED: 'edit_property_clicked',
    HIDE_PROPERTY_CLICKED: 'hide_property_clicked',
    VIDEO_IN_VIEW: 'video_in_view',
    VIDEO_PLAYED: 'video_played',
    VIDEO_PAUSED: 'video_paused',
    CREATE_APPOINTMENT_SLOT_CLICKED: 'create_appointment_slot_clicked',
    HIDE_APPOINTMENT_SLOT_CLICKED: 'hide_appointment_slot_clicked',
    CANCEL_APPOINTMENT_SLOT_CLICKED: 'cancel_appointment_slot_clicked',
    SHOW_APPOINTMENT_SLOT_DETAILS_CLICKED: 'show_appointment_slot_details_clicked',
    ATTENDEE_TEL_CLICKED: 'attendee_tel_clicked',
};

const LABELS = {
    LEASE_REQUEST_OPENED: 'lease_request_opened',
    PROPERTY_DETAILS_OPENED: 'property_details_opened',
    LEASE_REQUEST_PROPERTY_DETAILS_OPENED: 'lease_request_property_details_opened',
    EMAIL_CONFIRMATION_SUCCESSFUL: 'email_confirmation_successful',
    EMAIL_CONFIRMATION_FAILED: 'email_confirmation_failed',
    PUBLIC_SEARCH_OPENED: 'public_search_opened',
    HOME_OPENED: 'home_opened',
};

export function getPageViewLabel(path: string): string {
    const pathname = path.split('?')[0];
    let label = '';
    if (pathname === '/' || pathname === '/en') {
        label = LABELS.HOME_OPENED;
    } else if (path?.includes('lease-request?id')) {
        label = LABELS.LEASE_REQUEST_OPENED;
    } else if (path?.includes('my/property?id')) {
        label = LABELS.LEASE_REQUEST_PROPERTY_DETAILS_OPENED;
    } else if (path?.includes('searches/property?id')) {
        label = LABELS.PROPERTY_DETAILS_OPENED;
    } else if (pathname?.includes('account/confirm/success')) {
        label = LABELS.EMAIL_CONFIRMATION_SUCCESSFUL;
    } else if (pathname?.includes('account/confirm/failed')) {
        label = LABELS.EMAIL_CONFIRMATION_FAILED;
    } else if (pathname?.includes('/meta-search') || pathname?.includes('/metasuche')) {
        label = LABELS.PUBLIC_SEARCH_OPENED;
    }
    return label;
}

export const gtm = {
    event,
    pageView,
    CATEGORIES,
    EVENTS,
};
