import { NextRouter } from 'next/router';

type QueryParameter = Record<string, string | string[] | null | undefined>;

export const QUERY_PARAMS = {
    REFERRER: 'referrer',
};

/**
 * Selects the query parameter with the given name and returns it as an array.
 * @param router The Next.js router
 * @param name The query parameter name
 */
export function parameterAsArray(router: NextRouter, name: string): string[] {
    return queryParameterAsArray(router.query, name);
}

/**
 * Selects the query parameter with the given name and returns it as an array.
 * @param query The query object
 * @param name The query parameter name
 */
export function queryParameterAsArray(query: QueryParameter, name: string): string[] {
    const param = query[name];
    if (typeof param === 'undefined' || param === null) {
        return [];
    } else if (Array.isArray(param)) {
        return param;
    } else {
        return [param];
    }
}

/**
 * Selects the query parameter with the given name and returns it as a string.
 * @param router The Next.js router
 * @param name The query parameter name
 * @param select A function that selects the value from the array of values (default: the first value)
 */
export function parameterAsString(
    router: NextRouter,
    name: string,
    select: (v: string[]) => string = v => v[0]
): string | undefined {
    return queryParameterAsString(router.query, name, select);
}

/**
 * Selects the query parameter with the given name and returns it as a string.
 * @param query The Next.js query params
 * @param name The query parameter name
 * @param select A function that selects the value from the array of values (default: the first value)
 */
export function queryParameterAsString(
    query: QueryParameter,
    name: string,
    select: (v: string[]) => string = v => v[0]
): string | undefined {
    const param = query[name];
    if (typeof param === 'undefined' || param === null || (param + '').toLowerCase() === 'null') {
        return undefined;
    } else if (Array.isArray(param)) {
        return select(param);
    } else {
        return param;
    }
}

/**
 * Selects the query parameter with the given name and returns it as an object deserialized from base64.
 * @param router The Next.js router
 * @param name the query parameter name
 * @returns the deserialized object or undefined if the parameter is not present or the deserialization fails
 */
export function parameterAsObject<T>(router: NextRouter, name: string): T | undefined {
    const serialized = parameterAsString(router, name);
    if (!serialized) return undefined;
    try {
        const deserialized = Buffer.from(serialized, 'base64').toString('utf-8');
        return JSON.parse(deserialized) as T;
    } catch (e) {
        return undefined;
    }
}

/**
 * Serializes the given object to base64 and returns for use as a query parameter.
 * @param value The value to serialize
 */
export function serializedParameter<T>(value: T | undefined | null): string | undefined {
    if (!value) return undefined;
    return Buffer.from(JSON.stringify(value)).toString('base64').toString();
}

/**
 * Saves the query parameter with the given name to a storage
 * @param query The Next.js query params
 * @param name The query parameter name
 */
export function saveQueryParam(query: QueryParameter, name: string) {
    try {
        const value = queryParameterAsString(query, name)?.toLowerCase();

        if (!value) {
            return;
        }

        const savedValue = localStorage.getItem(name);

        if (savedValue && savedValue === value) {
            return;
        }
        localStorage.setItem(name, value);
    } catch (e) {
        return;
    }
}

/**
 * Retrieves the query parameter value with the given name from a storage
 * @param name The query parameter name
 */
export function getSavedQueryParam(name: string): string | null {
    try {
        return localStorage.getItem(name);
    } catch (e) {
        return null;
    }
}
