import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import moment from 'moment';
export type RefreshTokenHandlerProps = {
    setInterval: (interval: number) => void;
};
const RefreshTokenHandler = (props: RefreshTokenHandlerProps) => {
    const { data: session } = useSession();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const { setInterval = () => {} } = props || {};
    useEffect(() => {
        if (session?.accessTokenExpiry !== null && session?.accessTokenExpiry !== undefined) {
            const expires = moment(session.accessTokenExpiry);
            // We did set the token to be ready to refresh after 23 hours, here we set interval of 23 hours 30 minutes.
            const timeRemaining = expires.diff(moment(), 'seconds');
            setInterval(timeRemaining > 0 ? timeRemaining : 0);
        }
    }, [session, setInterval]);

    return null;
};

export default RefreshTokenHandler;
