import { notEmpty } from './collection';

type IdentifierFunction = (...key: (string | undefined | null)[]) => string;

/**
 * Creates a function convert kebab/snake case identifiers into a camel case one
 * @param {{lowerFirstLetter?: boolean}} props Configure some behaviour
 * @return {IdentifierFunction} The converter function.
 */
export function camelCaseFunction(props: { lowerFirstLetter?: boolean } = {}): IdentifierFunction {
    const { lowerFirstLetter = false } = props;
    return function camelCase(...key: (string | undefined | null)[]) {
        const value = key
            .filter(notEmpty)
            .flatMap(key => key.split(/(?=[_|-|\W|.])/g).map((p, idx) => (idx > 0 ? p.slice(1) : p)))
            .flatMap(part => part.split(/(?=[A-Z])/g))
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join('');
        return lowerFirstLetter && value.length > 0 ? `${value.charAt(0).toLowerCase()}${value.slice(1)}` : value;
    };
}

/**
 * Default convert function that converts kebab/snake case identifiers into camel case ones
 * @type {IdentifierFunction}
 */
export const camelCase = camelCaseFunction({ lowerFirstLetter: true });

/**
 * Default convert function that converts kebab/snake case identifiers into pascal case ones (upper-case first letter)
 * @type {IdentifierFunction}
 */
export const pascalCase = camelCaseFunction({ lowerFirstLetter: false });

/**
 * Creates a function convert kebab/camel case identifiers into a name case ones
 @param {{case?: "upper" | "lower"}} props Configure some behaviour
 * @return {IdentifierFunction} The converter function.
 */
export function snakeCaseFunction(props: { case?: 'upper' | 'lower' } = {}): IdentifierFunction {
    const { case: c = 'lower' } = props;
    return function snakeCase(...key: (string | undefined | null)[]) {
        return key
            .filter(notEmpty)
            .flatMap(key => key.split(/(?=[-|\W|.])/g).map((p, idx) => (idx > 0 ? p.slice(1) : p)))
            .flatMap(part => part.split(/(?=[A-Z0-9])/g))
            .map(part => (c === 'lower' ? part.toLowerCase() : part.toUpperCase()))
            .join('_');
    };
}

/**
 * Default convert function that converts kebab/camel case identifiers into snake case ones
 * @type {IdentifierFunction}
 */
export const snakeCase = snakeCaseFunction({ case: 'lower' });

/**
 * Converts snake/camel case identifiers into kebab-case ones.
 * @param {string | undefined | null} keys A list of identifiers
 * @return {string} A kebab case identifiert
 */
export function kebabCase(...keys: (string | undefined | null)[]) {
    return keys
        .filter(notEmpty)
        .flatMap(key => key.split(/(?=[_|\W|.])/g).map((p, idx) => (idx > 0 ? p.slice(1) : p)))
        .flatMap(part => part.split(/(?=[A-Z0-9])/g))
        .filter(p => !!p && !!p.length)
        .map(part => part.toLowerCase())
        .join('-');
}
