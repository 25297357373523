import '../styles/site.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ToastContainer } from 'react-toastify';
import { BffProvider } from '@/lib/api/bff';
import { CookiesProvider } from 'react-cookie';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { LoggerErrorBoundary, notify } from '@/lib/utilities/logger';
import { useRouter } from 'next/router';
import { gtm, QUERY_PARAMS, saveQueryParam } from '@/lib/utilities';
import { defaultSEO } from '../../next-seo.config';
import { DefaultSeo } from 'next-seo';
import api from '../lib/api/api';
import graphCms, { GraphCmsProvider } from '@/lib/api/graphcms';
import { NextPage } from 'next';
import { TOAST_CONTAINER_PROPS } from '@/styles/toasts';
import { SessionProvider, useSession } from 'next-auth/react';
import NextTopLoader from 'nextjs-toploader';

import 'react-toastify/dist/ReactToastify.css';
import '@reach/combobox/styles.css';
import 'rc-slider/assets/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-day-picker/dist/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import RefreshTokenHandler from '../components/refresh-token-handler';
import Script from 'next/script';
import { chatbaseWidgetId } from '@/lib/env';
import { loader } from '@/lib/api/google';

export type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

function WebsiteApp(props: AppPropsWithLayout & { session: any }) {
    const [refreshAuthTokenInterval, setRefreshAuthTokenInterval] = useState(0);
    if (!LoggerErrorBoundary)
        return (
            <MultiProvider
                providers={[
                    // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react/jsx-key
                    <CookiesProvider />,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react/jsx-key
                    <BffProvider sdk={api} />,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react/jsx-key
                    <GraphCmsProvider cmsClient={graphCms} />,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line react/jsx-key
                    <SessionProvider
                        session={props.session}
                        refetchInterval={refreshAuthTokenInterval}
                        refetchOnWindowFocus={false}
                    />,
                ]}
            >
                <RefreshTokenHandler setInterval={setRefreshAuthTokenInterval} />
                <OwnrWebsite {...props} />
            </MultiProvider>
        );

    return (
        <MultiProvider
            providers={[
                // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line react/jsx-key
                <CookiesProvider />,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line react/jsx-key
                <BffProvider sdk={api} />,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line react/jsx-key
                <GraphCmsProvider cmsClient={graphCms} />,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line react/jsx-key
                <SessionProvider
                    session={props.session}
                    refetchInterval={refreshAuthTokenInterval}
                    refetchOnWindowFocus={false}
                />,
            ]}
        >
            <LoggerErrorBoundary FallbackComponent={ErrorView}>
                <OwnrWebsite {...props} />
            </LoggerErrorBoundary>
        </MultiProvider>
    );
}

const ErrorView = () => {
    const router = useRouter();
    useEffect(() => {
        if (router) {
            router.push('/404');
        }
    }, []);

    return <></>;
};

export default appWithTranslation(WebsiteApp);

const useSaveReferrer = () => {
    const router = useRouter();
    useEffect(() => {
        saveQueryParam(router.query, QUERY_PARAMS.REFERRER);
    }, [router.query]);
};

function OwnrWebsite({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? (page => page);
    const router = useRouter();
    const session = useSession();

    useSaveReferrer();

    useEffect(() => {
        const loadGoogleApi = async () => await loader.importLibrary('places');

        try {
            loadGoogleApi();
        } catch {
            notify('Loading google maps api failed', 'google-maps-api');
        }
    }, []);

    useEffect(() => {
        if (session.status !== 'loading') gtm.pageView(router.asPath, session?.data);
    }, [router.asPath, session?.data, session?.status]);

    return (
        <>
            <Head>
                <DefaultSeo {...defaultSEO} />
                <meta name='viewport' content='width=device-width, initial-scale=1' />
            </Head>
            <ChatBaseWidget />
            <NextTopLoader color='var(--bs-velvet-500)' showSpinner={false} />
            {getLayout(<Component {...pageProps} />)}
            <ToastContainer {...TOAST_CONTAINER_PROPS} />
        </>
    );
}

const nest = (children: React.ReactNode, component: React.ReactElement) => React.cloneElement(component, {}, children);

export type MultiProviderProps = React.PropsWithChildren<{
    providers: React.ReactElement[];
}>;

const MultiProvider: React.FC<MultiProviderProps> = ({ children, providers }) => (
    <React.Fragment>{providers.reduceRight(nest, children)}</React.Fragment>
);

const ChatBaseWidget = () => {
    return (
        <>
            <Script strategy='lazyOnload' id='chatBaseSetup'>
                {`window.embeddedChatbotConfig = { chatbotId: '${chatbaseWidgetId}', domain: "www.chatbase.co" }`}
            </Script>
            <Script strategy='lazyOnload' id='chatBase' src='https://www.chatbase.co/embed.min.js' defer></Script>
        </>
    );
};
