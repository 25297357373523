import { Stage as ContentStage } from '@/lib/api/graphcms/lib/graphql'; // needed to prevent circular dependency
import process from 'process';

export type Stage = 'local' & ServiceStage;
export type ServiceStage = 'dev' | 'staging' | 'prod';

export const stage = (process.env.NEXT_PUBLIC_STAGE as Stage) || 'local';
export const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || process.env.VERCEL_GIT_COMMIT_SHA || '';
export const subdomain = process.env.NEXT_PUBLIC_SUBDOMAIN || 'www';

export const isLocal = stage === 'local';
export const serviceStage = (isLocal ? 'dev' : stage) as ServiceStage;
export const graphCmsConfig = {
    apiToken: process.env.NEXT_PUBLIC_GRAPHCMS_API_TOKEN,
    host: process.env.NEXT_PUBLIC_GRAPHCMS_HOST,
    projectId: process.env.NEXT_PUBLIC_GRAPHCMS_PROJECT,
    environment: process.env.NEXT_PUBLIC_GRAPHCMS_ENVIRONMENT,
    endpoint: process.env.NEXT_PUBLIC_GRAPHCMS_ENDPOINT,
};

export const awsRegion = 'eu-central-1';
export const graphCmsSecret = process.env.NEXT_PUBLIC_GRAPHCMS_SECRET || '';

export const websiteBffToken = process.env.NEXT_PUBLIC_WEBSITE_BFF_TOKEN;
export const previewSecret = process.env.NEXT_GRAPHCMS_PREVIEW_SECRET;
export const defaultContentStage =
    (process.env.NEXT_GRAPHCMS_CONTENT_STAGE_DEFAULT as ContentStage) || ContentStage.PUBLISHED;
export const serviceUrl = (...subdomains: string[]) => {
    const ds = [...subdomains];
    if (serviceStage !== 'prod') ds.push(serviceStage);
    return ds.join('.') + '.ownr.eu';
};
export const absoluteUrl = (pathname: string) => {
    if (isServerside()) return `${websiteBaseUrl}${pathname?.startsWith('/') ? '' : '/'}${pathname}`;
    return `${pathname?.startsWith('/') ? '' : '/'}${pathname}`;
};
export const websiteBaseUrl = stage === 'local' ? 'https://localhost.ownr.eu:4430' : `https://${serviceUrl(subdomain)}`;
export const isProduction = stage === 'prod';
export const inBrowser = () => typeof window !== 'undefined';
export const isServerside = () => typeof window === 'undefined';
export const runsOnVercel = Boolean(process.env.VERCEL);

export const authBaseUrl = `https://${serviceUrl('auth')}`;
export const authUser = !process.env.NEXT_AUTH_USER
    ? null
    : {
          user: process.env.NEXT_AUTH_USER,
          password: process.env.NEXT_AUTH_PASSWORD,
          whitelistedIps: !process.env.NEXT_WHITELISTED_IPS ? [] : process.env.NEXT_WHITELISTED_IPS.split(' '),
      };

export const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';
export const gtmContainerId = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || '';
export const ccmApiKey = process.env.NEXT_PUBLIC_CCM_API_KEY || '';
export const ccmDomain = process.env.NEXT_PUBLIC_CCM_DOMAIN || '';
export const cognitoClientId = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID || '';
export const cognitoClientSecret = process.env.NEXT_PUBLIC_COGNITO_CLIENT_SECRET || '';
export const cognitoIdentityPoolId = process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID || '';

export const cognitoUserPool = process.env.NEXT_PUBLIC_COGNITO_USER_POOL || '';
export const chatbaseWidgetId = process.env.NEXT_PUBLIC_CHAT_BASE_WIDGET_ID;
export const typeFormLink = process.env.NEXT_PUBLIC_TYPEFORM_LINK || '';

export const apiGatewayConfig = {
    baseUrl: isLocal
        ? process.env.NEXT_PUBLIC_PROPERTY_SERVICE_LOCAL ?? 'https://hub.dev.ownr.eu/'
        : process.env.NEXT_PUBLIC_PROPERTY_SERVICE_BASE_URL ?? 'https://hub.dev.ownr.eu/',
};

export const salesforceUrl =
    serviceStage === 'prod'
        ? 'https://ownr.lightning.force.com/lightning'
        : 'https://ownr--qa.sandbox.lightning.force.com/lightning';

export const immoScoutExposeUrl =
    serviceStage === 'prod'
        ? 'https://www.immobilienscout24.de/expose'
        : 'https://www.sandbox-immobilienscout24.de/expose';

export const immoweltExposeUrl = 'https://www.immowelt.de/expose';
