export * from './lib/graphcms';
export * from './lib/graphql';

import { Stage } from './lib/graphql';
import { graphCms as cms } from './lib/graphcms';
import { defaultContentStage, graphCmsConfig, isLocal } from '@/lib/env/lib/env';

const graphCms = cms(graphCmsConfig);
export default graphCms;

export function stage(preview = defaultContentStage === Stage.DRAFT) {
    return { stage: isLocal ? Stage.DRAFT : preview ? Stage.DRAFT : Stage.PUBLISHED };
}
