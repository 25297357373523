import { notEmpty } from './collection';

const compressAndWebp = (webp: boolean) => `${webp ? 'auto_image/' : ''}compress`;
type ImageFit = 'clip' | 'crop' | 'scale' | 'max';
export const baseMediaUrl = 'https://media.graphassets.com';

type ResizeImageProps = {
    width: number | null;
    height: number | null;
    fit?: ImageFit;
};

export function resizeImage({ width, height, fit }: ResizeImageProps): string {
    return `resize=w:${width},h:${height},fit:${fit}`;
}

type UrlFactoryFunction = (resize?: string) => (transforms?: string[]) => string;

export function constructURL(handle: string, withWebp: boolean, baseURI = baseMediaUrl): UrlFactoryFunction {
    return (resize = '') =>
        (transforms = []) => {
            return [baseURI, resize, ...transforms, compressAndWebp(withWebp), handle]
                .filter(v => v.length > 0)
                .filter(notEmpty)
                .join('/');
        };
}
export const srcSet = (srcBase: UrlFactoryFunction, srcWidths: number[], fit: ImageFit = 'clip', transforms = []) =>
    srcWidths
        .map(width => `${srcBase(`resize=w:${Math.floor(width)},fit:${fit}`)(transforms)} ${Math.floor(width)}w`)
        .join(',\n');

export const getWidths = (width: number, maxWidth = 800) => {
    const sizes = responsiveSizes(maxWidth).filter(size => size < width);
    // Add the original width to ensure the largest image possible
    // is available for small images.
    return [...sizes, width];
};

const responsiveSizes: (size: number) => number[] = size => [size / 4, size / 2, size, size * 1.5, size * 2, size * 3];
