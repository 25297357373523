// log the pageview with their URL
import { event as evnt } from './google-tag-manager';
export type EventParameter = {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: number;
    transport?: 'beacon';
};

function event({ eventAction, eventCategory, eventLabel, eventValue }: EventParameter): void {
    evnt(eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue,
    });
}

export const ga = {
    event,
};
